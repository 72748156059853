import React, { Fragment } from 'react';
import axios from 'axios';
import PostCarousel from "../../components/PostCarousel";
import PostGallery from "../../components/PostGallery";
import FeatureNews from "../../components/FeatureNews";
import TrendingNews from "../../components/TrendingNews";
import FollowUs from "../../components/FollowUs";
import MostView from "../../components/MostView";
import MixCarousel from "../../components/MixCarousel";
import VideoPost from "../../components/VideoPost";
import EntertainmentNews from "../../components/EntertainmentNews";
import { Link } from "react-router-dom";
import SportsNews from "../../components/SportsNews";
import BusinessNews from "../../components/BusinessNews";
import MostShareWidget from "../../components/MostShareWidget";
import UpcomingMatches from "../../components/UpcomingMatches";
import NewsLetter from "../../components/NewsLetter";
import CategoriesWidget from "../../components/CategoriesWidget";

// images
import banner1 from '../../doc/img/bg/banner1.png';
import banner2 from '../../doc/img/bg/sidebar-1.png';
import { connect } from 'react-redux';

class HomePage extends React.Component {
    
    render() {
        const post = this.props.news;
        return (
            <Fragment>
                <div>
                    {post && <div>
{                        <PostCarousel data={post.trendingNews} className="fifth_bg" />
 }                        { <PostGallery className="fifth_bg" /> }
                        <FeatureNews data={post.featureNews} />
                       {/*  <div className="container">
                            <div className="row">
                                { <div className="col-lg-8">
                                    <TrendingNews data={post.trendingNews} />
                                </div> }
                                { <div className="col-md-12 col-lg-4">
                                    <FollowUs title="Follow Us" />
                                    <MostView data={post.mostViewedNews} />
                                </div> }
                            </div>
                        </div> */}
                        {/* { <MixCarousel className="half_bg1"/> } */}
                        {/* {<VideoPost className="pt30 half_bg60"/> } */}
                        <div className="entertrainments">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="heading">
                                                    <h2 className="widget-title">Entertrainment News</h2>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="entertrainment_carousel mb30">
                                            <div className="entertrainment_item">
                                                <div className="row justify-content-center">
                                                    <EntertainmentNews entertainments={post.entertainmentNews} />
                                                </div>
                                            </div>
                                        </div>

{/*                                         <SportsNews sportsNews={post.sportsNews} />
 */}                                        {/* <div className="banner_area mt50 mb60 xs-mt60">
                                            <Link to="/">
                                                <img src={banner1} alt="banner1" />
                                            </Link>
                                        </div> */}
                                        {/* <BusinessNews businessNews={post.businessNews} /> */}
                                    </div>
                                    {<div className="col-lg-4">
                            <div className="row">
                                {/* <div className="col-lg-12">
                                    <MostShareWidget title="Most share"/>
                                </div> */}
                                <div className="col-lg-12">
                                    <UpcomingMatches data={post.upcomingMatches}/>
                                </div>
                                <div className="col-lg-12">
                                    <NewsLetter/>
                                </div>
                                {/* <div className="col-lg-12">
                                    <CategoriesWidget/>
                                </div> */}
                               {/*  <div className="col-lg-12">
                                    <div className="banner2 mb30">
                                        <Link to="/">
                                            <img src={banner2} alt="thumb"/>
                                        </Link>
                                    </div>
                                </div> */}
                            </div>
                        </div> }
                                </div>
                            </div>
                        </div>
                        <div className="space-70" />
                    </div>
                    }
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        news: state?.meta?.newsList
    }
}
export default connect(mapStateToProps)(HomePage);