import React, {Fragment} from "react";
import {toast, ToastContainer} from "react-toastify";
import {connect} from "react-redux";
import Routes from "../__Routes";
import ScrollTopButton from "../../components/ScrollTopButton";
import { fetchPosts } from '../../store/actions';

class App extends React.Component{
    constructor(props){
        super(props);
        const {error, success} = this.props;
        if (error) toast.error(error);
        if (success) toast.success(success);
    }
   
    componentDidMount() {
        this.props.fetchPosts();
    }

    render(){
        console.log(this.props.news);
        return (
            <Fragment>
                {!this.props.news && 
                    <div className="preloader dark-p">
                        <div>
                            <div className="nb-spinner"></div>
                        </div>
                    </div>
                }
                <Routes/>
                <ToastContainer position="top-center"/>
                <ScrollTopButton/>
            </Fragment>
        );
    }
};

const MapStateToProps = state => {
    return {
        error: state.meta.error,
        success: state.meta.success,
        news: state?.meta?.newsList
    }
};

export default connect(MapStateToProps, { fetchPosts })(App);
