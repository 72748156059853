import React, {Fragment} from 'react';
import {Link} from "react-router-dom";
import enter1 from '../../doc/img/entertrainment/enter1.jpg';
const EntertainmentNews = ({entertainments}) => {
    return (
        <Fragment>
            {entertainments && entertainments.map((item, i) => (
                <div key={i} className="col-lg-6">
                    <div className="single_post post_type3 mb30">
                        <div className="post_img">
                            <div className="img_wrap">
                                <Link to="/">
                                    <img src={item.Image?item.Image: enter1} alt="thumb"/>
                                </Link>
                            </div>
                        </div>
                        <div className="single_post_text">
                            <div className="meta3"><Link to="/">{item.Category}</Link>
                                <Link to="/">{item.pun}</Link>
                            </div>
                            <h4><a href={item.Url} target="_blank">{item.Title}</a></h4>
                            <div className="space-10"/>
                            <p className="post-p">{item.Description}</p>
                        </div>
                    </div>
                </div>
            ))}
        </Fragment>
    );
};

export default EntertainmentNews;