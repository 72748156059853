import React, {Fragment} from 'react';
import BreadCrumb from "../../components/BreadCrumb";
import FontAwesome from "../../components/uiStyle/FontAwesome";
import {Link} from "react-router-dom";
import WidgetTab from "../../components/WidgetTab";
import WidgetTrendingNews from "../../components/WidgetTrendingNews";
import NewsLetter from "../../components/NewsLetter";
import MostShareWidget from "../../components/MostShareWidget";
import FollowUs from "../../components/FollowUs";
import BannerSection from "../../components/BannerSection";
import PostOnePagination from "../../components/PostOnePagination";
import { getSelectNews } from '../../store/actions';

// images
import banner2 from "../../doc/img/bg/sidebar-1.png";
import big2 from '../../doc/img/blog/big2.jpg';
import author2 from '../../doc/img/author/author2.png';
import quote from '../../doc/img/icon/q.png';
import quote_1 from '../../doc/img/blog/quote_1.jpg';
import big1 from '../../doc/img/blog/big1.jpg';
import smail1 from '../../doc/img/blog/smail1.jpg';
import single_post1 from '../../doc/img/blog/single_post1.jpg';
import OurBlogSection from "../../components/OurBlogSection";
import BlogComment from "../../components/BlogComment";
import { connect } from 'react-redux';

class PostOnePage extends React.Component {
    constructor(props){
        super(props);
    }
   
    componentDidMount() {
        this.props.getSelectNews();
    }

    render(){
    return (
        <Fragment>
            <div className="archives post post1">
                <BreadCrumb className="shadow5 padding-top-30" title="Archive / post 1"/>
                <span className="space-30"/>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-8">
                            <div className="row">
                                <div className="col-6 align-self-center">
                                    <div className="page_category">
                                        <h4>{this.props?.currentNews?.Category}</h4>
                                    </div>
                                </div>
                                <div className="col-6 text-right">
                                    <div className="page_comments">
                                        <ul className="inline">
                                            <li><FontAwesome name="comment"/>563</li>
                                            <li><FontAwesome name="fire"/>563</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="space-30"/>
                            <div className="single_post_heading">
                                <h1>{this.props?.currentNews?.Title}</h1>
                                <div className="space-10"/>
                            </div>
                            <div className="space-40"/>
                            <img src={this.props?.currentNews?.Image || big1} alt="thumb"/>
                            <div className="space-20"/>
                            <div className="row">
                                <div className="col-lg-6 align-self-center">
                                    <div className="author">
                                        <div className="author_img">
                                            <div className="author_img_wrap">
                                                <img src={author2} alt="author2"/>
                                            </div>
                                        </div>
                                        <Link to="/">Source: {this.props?.currentNews?.Source}</Link>
                                        <ul>
                                            {/* <li><Link to="/">March 26, 2020</Link></li> */}
                                            <li>Updated {this.props?.currentNews?.Published_at.split('T')[0]}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6 align-self-center">
                                    <div className="author_social inline text-right">
                                        <ul>
                                            <li><Link to="/"><FontAwesome name="instagram"/></Link></li>
                                            <li><Link to="/"><FontAwesome name="facebook-f"/></Link></li>
                                            <li><Link to="/"><FontAwesome name="youtube"/></Link></li>
                                            <li><Link to="/"><FontAwesome name="instagram"/></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="space-20"/>
                            <div className="single_post_heading">
                                <div className="space-10"/>
                                <p>{this.props?.currentNews?.Description}</p>
                            </div>
                            <div className="space-20"/>
                            <a href={this.props?.currentNews?.Url} target="_blank"> Read Original >></a>
                            <div className="space-40"/>
                            <div className="border_black"/>
                            <div className="space-40"/>
                            <PostOnePagination/>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <WidgetTab/>
                            <FollowUs title="Follow Us"/>
                            <WidgetTrendingNews/>
                            <div className="banner2 mb30">
                                <Link to="/">
                                    <img src={banner2} alt="thumb"/>
                                </Link>
                            </div>
                            <MostShareWidget title="Most Share"/>
                            <NewsLetter/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="space-60"/>
            <OurBlogSection/>
            <div className="space-60"/>
            <BlogComment/>
            <div className="space-100"/>
            <BannerSection/>
        </Fragment>
    )
    }
};

const mapStateToProps = state => {
    return {
        currentNews: state.meta.currentNews,
        news: state.meta.newsList,
    }
}
export default connect(mapStateToProps, {getSelectNews})(PostOnePage);
