import React, {Component} from 'react';
import {Link} from "react-router-dom";
import WidgetTab from "../WidgetTab";
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import FontAwesome from "../uiStyle/FontAwesome";
import ModalVideo from 'react-modal-video'

// images
import gsil1 from '../../doc/img/dummyImg.PNG';
import gsil2 from '../../doc/img/dummyImg.PNG';
import gsil3 from '../../doc/img/dummyImg.PNG';
import gsil4 from '../../doc/img/dummyImg.PNG';
import gsil5 from '../../doc/img/dummyImg.PNG';
import gsil6 from '../../doc/img/dummyImg.PNG';
import gsil7 from '../../doc/img/dummyImg.PNG';
import sliderImg1 from '../../doc/img/header/sider-top.jpg';
import sliderImg2 from '../../doc/img/header/sider-top2.jpg';

import './style.scss';

const thumbs = [gsil1, gsil2, gsil3, gsil4];
const postSlider = [
    {
        image: sliderImg1,
        title: 'IPL: IS RCB the worst team of this IPL?',
        option1: 'RCB',
        option2: 'MI',
        option3: 'DC',
        option4: 'PUNJAB'
    },
    {
        image: sliderImg2,
        title: 'IPL: Strongest team of IPL?',
        option1: 'CSK',
        option2: 'KKR'
    },
    {
        image: sliderImg2,
        title: 'Elections 2024: Who will win?',
        option1: 'BJP',
        option2: 'Congress'
    },
    {
        image: sliderImg2,
        title: 'Is India developing?',
        option1: 'Yes',
        option2: 'No'
    }
];

function SampleNextArrow(props) {
    const {className, onClick} = props;
    return (
        <div onClick={onClick} className={`${className} slider_arrow arrow_right slick-arrow`}>
            <FontAwesome name="angle-right"/>
        </div>
    );
}

function SamplePrevArrow(props) {
    const {className, onClick} = props;
    return (
        <div onClick={onClick} className={`${className} slider_arrow arrow_left slick-arrow`}>
            <FontAwesome name="angle-left"/>
        </div>
    );
}

class PostGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
            vModal: false,
            videoId: '0r6C3z3TEKw',
            showRadio: true
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    modalHandler = (value) => {
        this.setState({
            vModal: value
        })
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            showRadio: false
        });
        setTimeout(function () {
            this.setState({ showRadio: true});
          }.bind(this), 2000)
    }

    render() {
        const {className} = this.props;
        const {nav1, nav2, vModal, videoId, showRadio} = this.state;

        const navSettings = {
            nextArrow: <SampleNextArrow/>,
            prevArrow: <SamplePrevArrow/>,
            slidesToShow: 3,
            swipeToSlide: true,
            focusOnSelect: true,
            centerMode: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 8,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 5,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 3,
                    }
                },
            ]
        };
        return (
            <div className={`post_gallary_area mb40 ${className}`}>
                <div className="container overFlowH">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-xl-8">
                                    <div className="slider_demo2">
                                        <Slider
                                            asNavFor={nav2}
                                            arrows={false}
                                            fade={true}
                                            ref={slider => (this.slider1 = slider)}
                                        >
                                            {postSlider?.map((item, i) => (
                                                <div key={i} className="single_post post_type6 xs-mb30">
                                                    <div className="post_img poll-wrapper">
                                                        <img style={{zIndex:-1}} src={sliderImg1 } alt="thumb"/>
                                                        {/* <span onClick={() => this.modalHandler(true)}
                                                              className="tranding"><FontAwesome
                                                            name="play"/></span> */}
                                                    </div>
                                                    <div className="single_post_text poll-section">
                                                        <div className="meta meta_separator1">
                                                            {/* <Link to="#">{item.Category}</Link> */}
                                                            {/* <Link to="#">{item?.Published_at?.split('T')[0]}</Link> */}
                                                        </div>
                                                        {showRadio && <h4><a href={item.Url} target="_blank">{item.title}</a></h4>}
                                                        <div className="space-10"/>
                                                        {/* <p className="post-p">{item.Description}</p> */}

                                                        <div className="checkbox-wrapper">
                                                            {showRadio &&
                                                            <form onSubmit={this.handleSubmit}>
                                                                {/* <label class="checkbox">
                                                                    <span class="checkbox__input">
                                                                        <input type="checkbox" name="checkbox"/>
                                                                        <span class="checkbox__control">
                                                                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true" focusable="false">
                                                                            <path fill='none' stroke='currentColor' stroke-width='3' d='M1.73 12.91l6.37 6.37L22.79 4.59' /></svg>
                                                                        </span>
                                                                    </span>
                                                                    <span class="radio__label">{item.checkBox1}</span>
                                                                </label>
                                                                <label class="checkbox">
                                                                    <span class="checkbox__input">
                                                                        <input type="checkbox" name="checkbox"/>
                                                                        <span class="checkbox__control">
                                                                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true" focusable="false">
                                                                            <path fill='none' stroke='currentColor' stroke-width='3' d='M1.73 12.91l6.37 6.37L22.79 4.59' /></svg>
                                                                        </span>
                                                                    </span>
                                                                    <span class="radio__label">{item.checkbox2}</span>
                                                                </label> */}
                                                                <div>
                                                                    <p className="mr-B-10">
                                                                        <input type="radio" id="test1" name="radio-group" checked/>
                                                                        <label for="test1">{item.option1}</label>
                                                                    </p>
                                                                    <p>
                                                                        <input type="radio" id="test2" name="radio-group"/>
                                                                        <label for="test2">{item.option2}</label>
                                                                    </p>
                                                                </div>
                                                                <button type="submit" className="btn mr-T-40">Submit</button>
                                                            </form>
                                                            } 
                                                            {!showRadio &&
                                                            <p style={{fontSize:25,marginTop: 75,marginLeft: 60}}>Thank you for submitting your response!</p>

                                                            }                                  
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                    <div className="slider_demo1">
                                        <Slider
                                            ref={slider => (this.slider2 = slider)}
                                            asNavFor={nav1}
                                            {...navSettings}
                                        >
                                            {thumbs?.map((item, i) => (
                                                <div key={i} className="single_gallary_item">
                                                    <p className="sliderText">Poll {i+1}</p>
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                </div>
                                <div className="col-xl-4">
                                    <WidgetTab dark={true}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalVideo channel='youtube' isOpen={vModal} videoId={videoId}
                            onClose={() => this.modalHandler(false)}/>
            </div>
        );
    }
}

export default PostGallery;