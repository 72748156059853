import {FETCH_NEWS, SELECT_NEWS, GET_SELECT_NEWS} from "../constants";
import newsApi from './../../API/newsApi';

export const fetchPosts = () => async dispatch => {
    const response = await newsApi.get('/LatestNewsWebAPILambda');
    window.localStorage.setItem('newsList', JSON.stringify(response));
    dispatch({
        type: FETCH_NEWS,
        payload: response.data
    });
};

export const selectNews = (news) => {
    window.localStorage.setItem('selectedNews', JSON.stringify(news));
    return {
        type: SELECT_NEWS,
        payload: news
    };
};

export const getSelectNews = () => {
   var selectedNews = JSON.parse(localStorage.getItem('selectedNews'));
    return {
        type: GET_SELECT_NEWS,
        payload: selectedNews
    };
};