import React, {useState} from 'react';
import {Link} from "react-router-dom";
import { connect } from 'react-redux';

import Swiper from 'react-id-swiper';
import FontAwesome from "../uiStyle/FontAwesome";
const baseURL = "https://9jkvwlj6l4.execute-api.us-east-1.amazonaws.com/default/GetNews";

const TopBar = ({className, dark, ...rest}) => {
    const {news} = {...rest};
    const [swiper, setSwiper] = useState(null);
    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };
    const params = {
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        }
    };

    const date = new Date();
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var dayName = days[date.getDay()];
    const formattedDate = date.toLocaleDateString('en-GB', {
    day: 'numeric', month: 'long', year: 'numeric'
    }).replace(/ /g, '-');
    
    return (
        <div className={`topbar ${className ? className : ''}`} id="top">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 align-self-center">
                        <div className={`trancarousel_area ${dark ? 'white' : ''}`}>
                            <p className="trand">Trending</p>
                            <div className="nav_style1">
                              { news &&
                                    <Swiper getSwiper={setSwiper} className="trancarousel" {...params}>
                                    {news.mostViewedNews?.map((item, i) => (
                                        <div className="trancarousel_item">
                                            <p><a href={item.Url} target="_blank">{item.Title}</a>
                                            </p>
                                        </div>
                                    ))}
                                    </Swiper>
                                }
                                <div className="navBtns">
                                    <button className="navBtn prevBtn" onClick={goPrev}><FontAwesome name="angle-left"/>
                                    </button>
                                    <button className="navBtn nextBtn" onClick={goNext}><FontAwesome
                                        name="angle-right"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 align-self-center">
                        <div className="top_date_social text-right">
                            <div className={`paper_date ${dark ? 'white' : ''}`}>
                                <p>{dayName}, {formattedDate}</p>
                            </div>
                            <div className={`social1 ${dark ? 'white' : ''}`}>
                                <ul className="inline">
                                    <li><Link to={{ pathname: "https://twitter.com/TruePolls_IN" }} target="_blank"><FontAwesome name="twitter"/></Link></li>
                                    <li><Link to={{ pathname: "https://www.facebook.com/TruePolls" }} target="_blank"><FontAwesome name="facebook-f"/></Link></li>
                                    <li><Link to={{ pathname: "https://www.youtube.com/channel/UCwoNu1gVvncXBpCZzBevgQw" }} target="_blank"><FontAwesome name="youtube-play"/></Link></li>
                                    <li><Link to={{ pathname: "https://www.instagram.com/truepolls.in/" }} target="_blank"><FontAwesome name="instagram"/></Link></li>
                            
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        news: state?.meta?.newsList
    }
}
export default connect(mapStateToProps)(TopBar);