import React, {Fragment, useState} from 'react';
import {TabContent, TabPane, Nav, NavItem, Fade} from 'reactstrap';
import classnames from 'classnames';
import {Link} from "react-router-dom";
import { connect } from 'react-redux';

import thumb1 from '../../doc/img/dummyImg.PNG';

const WidgetTabPane = ({arr, a_id, id, dark}) => {
    return (
        <Fade in={id === a_id}>
            <div className="widget tab_widgets">
                {arr?.map((item, i) => (
                    <Fragment key={i}>
                        <div className="single_post widgets_small">
                            <div className="post_img">
                                <div className="img_wrap">
                                    <Link to="/">
                                        <img src={item.Image ? item.Image : thumb1} alt="thumb"/>
                                    </Link>
                                </div>
                            </div>
                            <div className="single_post_text ">
                                <div className="meta2 meta_separator1 side-pan-date-source-style">
                                    <Link to="#">{item.Source}</Link>
                                    <Link to="#">{item.Published_at.split('T')[0]}</Link>
                                </div>
                                <h4><a href={item.Url} target="_blank">{item.Title}</a></h4>
                            </div>
                        </div>
                        <div className="space-15"/>
                        {dark ? <div className="border_white"/> : <div className="border_black"/>}
                        <div className="space-15"/>
                    </Fragment>
                ))}
            </div>
        </Fade>
    )
};

const WidgetTab = ({className, dark, ...rest}) => {
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const {news} = {...rest};

    return (
        <div className={`widget_tab md-mt-30 ${className}`}>
            <Nav tabs>
                <NavItem>
                    <Link
                        to="/"
                        className={classnames({active: activeTab === '1'})}
                        onClick={() => {
                            toggle('1');
                        }}
                    >
                        RELATED
                    </Link>
                </NavItem>
                <NavItem>
                    <Link
                        to="/"
                        className={classnames({active: activeTab === '2'})}
                        onClick={() => {
                            toggle('2');
                        }}
                    >
                        RECENT
                    </Link>
                </NavItem>
                <NavItem>
                    <Link
                        to="/"
                        className={classnames({active: activeTab === '3'})}
                        onClick={() => {
                            toggle('3');
                        }}
                    >
                        POPULAR
                    </Link>
                </NavItem>
            </Nav>
        
            <TabContent activeTab={activeTab}>
                    <TabPane tabId='1'><WidgetTabPane dark={dark} a_id={activeTab} id="1" arr={news?.relatedNews}/></TabPane>
                    <TabPane tabId='2'><WidgetTabPane dark={dark} a_id={activeTab} id="2" arr={news?.recentNews}/></TabPane>
                    <TabPane tabId='3'><WidgetTabPane dark={dark} a_id={activeTab} id="3" arr={news?.popularNews}/></TabPane>
            </TabContent>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        news: state?.meta?.newsList
    }
}
export default connect(mapStateToProps)(WidgetTab);
