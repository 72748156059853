import {IS_LOADING, RE_RENDERED, THROW_ERROR, THROW_SUCCESS, FETCH_NEWS, SELECT_NEWS, GET_SELECT_NEWS} from "../constants";
import {toast} from "react-toastify";

const init = {
    isLoading: false,
    error: '',
    success: '',
    reRendered: false,
    newsList:'',
    selectedNews:''
};

const metaReducer = (state = init, action) => {
    switch (action.type) {
        case THROW_ERROR: {
            toast.error(action.payload);
            return {
                ...state,
                error: action.payload
            }
        }
        case THROW_SUCCESS: {
            toast.success(action.payload);
            return {
                ...state,
                success: action.payload
            }
        }
        case IS_LOADING:
            return {
                ...state,
                isLoading: action.payload
            };
        case RE_RENDERED:
            return {
                ...state,
                reRendered: action.payload
            };
        case FETCH_NEWS:
            return {
                ...state,
                newsList: action.payload
            };
        case SELECT_NEWS:
            return {
                ...state,
                selectedNews: action.payload 
            };
        case GET_SELECT_NEWS:
            return {
                ...state,
                currentNews: action.payload 
            };
        default:
            return state;
    }
};

export default metaReducer;